@import "src/common/colors.scss";

#article-previews {
  #pagination {
    font-size: 12px;

    a {
      color: $green;
    }
  }
}