.author-previews {
  display: flex;
  flex-wrap: wrap;

  .item {
    width: 180px;
    height: 89px;

    a {
      display: flow-root;

      img {
        float: left;
        width: 40px;
        margin-right: 5px;
      }
    }
  }
}