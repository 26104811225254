@import "src/common/colors.scss";

.article {
  margin-bottom: 12px;

  .authors {
    border-bottom: 1px dotted;
    border-top: 1px dotted;
    padding: 4px 19px;
    background-color: #f1f1f1;
    margin-bottom: 10px;
    font-size: 12px;
  }

  span.more {
    a {
      color: $green;
    }
  }
}