@import "src/common/colors.scss";

#header {
  display: flex;
  justify-content: space-between;

  a#logo {
    display: block;
    margin: 10px 0 4px 0;
  }

  #search {
    display: flex;
    align-items: center;

    input {
      width: 165px;
      color: $grey;
      border: 1px solid #bbb;
      padding: 2px;
      font-size: 10px;
    }

    a {
      font-size: 11px;
      margin-left: 5px;
      padding: 4px;
    }
  }
}