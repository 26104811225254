@import "src/common/colors.scss";

#articles-archive-list {
  p.year {
    margin: 0;

    a {
      color: $green;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    line-height: 18px;

    li {
      font-size: 12px;

      a {
        color: #6d6e67;
      }
    }
  }
}