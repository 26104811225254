@import "src/common/colors.scss";

#footer {
  height: 190px;
  padding: 24px;
  background-image: url('../../../assets/footer-background.png');
  background-repeat: repeat-x;
  background-position: 0 -110px;
  font-weight: bold;

  .links {
    p {
      margin-bottom: 18px;

      a {
        color: $grey;
      }
    }

    height: 98px;
    margin-bottom: 33px;
  }

  #explanation {
    display: flex;

    img {
      opacity: 0.5;
      align-self: start;
    }

    p {
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      padding-top: 1px;
      padding-left: 18px;
    }
  }
}