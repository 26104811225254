#article-page {
  .text {
    line-height: 21px;
    font-size: 14px;

    h2 {
      font-size: 14px;
      padding-top: 11px;
    }
  }

  #side-column {
    h2 {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    img {
      margin-bottom: 19px;
    }
  }
}