@import "src/common/colors.scss";

#topics-page {
  a {
    color: $grey;
  }

  p {
    margin: 0;

    a {
      &:hover {
        color: $green;
      }
    }
  }

  ul {
    list-style: none;
    font-size: 12px;
    line-height: 20px;
  }
}