@import "src/common/colors";

#imprint {
  p {
    white-space: pre-line;
  }

  a {
    color: $green;
  }
}