@import "src/common/colors.scss";

#author {
  display: flex;

  #image-column {
    width: 120px;
    flex-shrink: 0;
  }

  a {
    color: $green;
  }
}