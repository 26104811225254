@import "src/common/colors.scss";

* {
  margin: 0;
  padding: 0;
}

body {
  background: #eee;
  font-family: Helvetica, Arial;
  font-size: 13px;
  color: $black;
  line-height: 20px;
}

#wrapper {
  width: 950px;
  margin: 0 auto;
}

p {
  margin-bottom: 7px;
}

ul {
  margin-bottom: 7px;
  padding-left: 23px;
}

a {
  text-decoration: none;
  color: $black;
}

#loading-spinner {
  margin-top: 22px;
}

h1 {
  color: #008f00;
  font-size: 18px;
  font-weight: normal;
  line-height: 27px;
  font-family: Georgia;
  margin-top: 22px;
  margin-bottom: 9px;

  a {
    color: $green;
  }
}

h2 {
  color: $grey;
  font-size: 13px;
  margin-bottom: 10px;
  padding-bottom: 8px;

  a {
    color: $grey;
  }
}